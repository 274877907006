import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryIcon from '@mui/icons-material/History';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoutButton from './elements/LogoutButton';
import ChangepasswordButton from './elements/ChangepasswordButton';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';

export const mainListItems = (
  <React.Fragment>

    <ListItemButton href="/welcome">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>

    <ListItemButton href="/portfolio">
      <ListItemIcon>
        <PieChartOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="My Portfolio" />
    </ListItemButton>

    <ListItemButton href="/mytradinglogs">
      <ListItemIcon>
        <HistoryIcon />
      </ListItemIcon>
      <ListItemText primary="My Trading Logs" />
    </ListItemButton>

  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Users
    </ListSubheader>
    <LogoutButton />
    <ChangepasswordButton />
  </React.Fragment>
);
