import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ShowIfAuth from '../../modules/ShowIfAuth';

const AuthHambergerMenu = function () {
    return (
        <span>
          <ListItem key="hambergerWelcome" disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href="/welcome">
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
        </span>
    );
}

export default ShowIfAuth(AuthHambergerMenu);