import { redirect } from "react-router-dom";
import Authenticate from "./Authenticate";

const VerifyAuth = async () => {
  const authenticated = await Authenticate();
  if (!authenticated) {
    return redirect('/login');
  }
  return null;
};

export default VerifyAuth;
