import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Cookies from 'js-cookie';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import DateTime from '../../elements/DateTime';
import { green, red } from '@mui/material/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { TableFooter } from '@mui/material';
import PriceDisplay from '../../elements/PriceDisplay';
import TableContainer from '@mui/material/TableContainer';

const redColor = red[700];
const greenColor = green[700];


export default function MyPortfolio() {
  /** Mytradinglog required  */
  const defaultTodate = new Date().toISOString().slice(0, 10); //it will be YYYY-mm-dd
  const defaultFromdate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10);//it will be YYYY-mm-dd

  const [fromdate, setFromdate] = useState(dayjs(defaultFromdate));
  const [todate, setTodate] = useState(dayjs(defaultTodate));

  const [rows, setRows] = useState([]);
  const [totalRealized, setTotalRealized] = useState(0);


  /**
   * 
   * @param {*} trade_time 
   * @param {*} start_volume 
   * @param {*} current_volume This is available volume on Streaming
   * @param {*} actual_volume 
   * @param {*} average_price 
   * @param {*} market_price 
   * @param {*} amount 
   * @param {*} market_value 
   * @param {*} profit This unrealize profit on Streaming
   * @param {*} relize_profit 
   * @returns 
   */
  function createRow(ID, created_at, symbol, start_volume, current_volume, actual_volume, average_price, market_price, amount, market_value, profit, realize_profit) {
    return {
      ID, created_at, symbol, start_volume, current_volume, actual_volume,
      average_price: average_price.toFixed(2),
      market_price: market_price.toFixed(2),
      amount, market_value, profit,
      realize_profit: realize_profit.toFixed(2)
    };
  }

  const getPortfolioList = function (fromdate, todate) {
    const token = Cookies.get('token');
    const from_date = fromdate.toISOString();
    const to_date = todate.toISOString();
    return fetch(`https://www.tradehoonthai.com/api/getPortfolioList`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          from_date: from_date,
          to_date: to_date,
        })
      }
    ).then(response => response.json());
  }


  const handlefromdate = function (newvalue) {
    setFromdate(newvalue);
  }
  const handletodate = function (newvalue) {
    setTodate(newvalue);
  }

  const handleViewOrder = function () {
    getPortfolioList(fromdate, todate).then(response => {
      if (response.status) {
        let countTotalRealized = 0;
        const orderRows = response.portfolioList.map(item => {
          countTotalRealized = countTotalRealized + item.realize_profit;
          return createRow(item.ID, item.created_at, item.symbol, item.start_volume, item.current_volume, item.actual_volume, item.average_price, item.market_price, item.amount, item.market_value, item.profit, item.realize_profit);
        }
        );
        setRows(orderRows);
        setTotalRealized(countTotalRealized);
      } else {
        setRows([]);
      }
    });
  }

  useEffect(() => {
    getPortfolioList(fromdate, todate).then(response => {
      if (response.status) {
        let countTotalRealized = 0;
        const orderRows = response.portfolioList.map(item => {
          countTotalRealized = countTotalRealized + item.realize_profit;
          return createRow(item.ID, item.created_at, item.symbol, item.start_volume, item.current_volume, item.actual_volume, item.average_price, item.market_price, item.amount, item.market_value, item.profit, item.realize_profit);
        }
        );
        setRows(orderRows);
        setTotalRealized(countTotalRealized);
      } else {
        setRows([]);
      }
    });
  }, []);
  /** Mytradinglog required  */

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            My Portfolio
          </Typography>
          <Stack
            justifyContent="flex-start"
            alignItems="baseline"
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 3, sm: 3, md: 3 }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
              <DatePicker value={fromdate} onChange={handlefromdate}
                defaultValue={dayjs(defaultFromdate)}
                label="Please choose begin date"
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}
              adapterLocale="th">
              <DatePicker value={todate} onChange={handletodate}
                defaultValue={dayjs(defaultFromdate)}
                label="Please choose end date"
              />
            </LocalizationProvider>
            <Button variant="outlined" onClick={handleViewOrder} size="large">View</Button>
          </Stack>
          <Divider sx={{ marginTop: '30px' }} />
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Symbol</TableCell>
                  {/* <TableCell align="left">Start Vol</TableCell> */}
                  <TableCell align="left">Avail Vol</TableCell>
                  {/* <TableCell align="left">Actual Vol</TableCell> */}
                  <TableCell align="left">Avg Cost</TableCell>
                  <TableCell align="left">Mkt Price</TableCell>
                  <TableCell align="left">Amount(Cost)</TableCell>
                  <TableCell align="left">Market Value</TableCell>
                  <TableCell align="left">Unrealized P/L</TableCell>
                  <TableCell align="left">Realized P/L</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {rows.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell align="left"><DateTime datetime={row.created_at} /></TableCell>
                    <TableCell align="left">{row.symbol}</TableCell>
                    {/* <TableCell align="right">{row.start_volume}</TableCell> */}
                    <TableCell align="right">{row.current_volume}</TableCell>
                    {/* <TableCell align="right">{row.actual_volume}</TableCell> */}
                    <TableCell align="right">{row.average_price}</TableCell>
                    <TableCell align="right">{row.market_price}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.market_value}</TableCell>
                    <TableCell align="right"><PriceDisplay floatValue={row.profit}></PriceDisplay></TableCell>
                    <TableCell align="right"><PriceDisplay floatValue={row.realize_profit}></PriceDisplay></TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {/* <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell> */}
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"><Typography variant="subtitle2" display="inline">Total Realized P/L</Typography></TableCell>
                  <TableCell align="right"><PriceDisplay floatValue={totalRealized} /></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}