import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Copyright from '../templates/CopyrightUnderLoginBox';
import { useNavigate } from 'react-router-dom';

export default function Signup() {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [usernameHelperText, setUsernameHelperText] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const [confirmpassword, setConfirmpassword] = useState('');
  const [confirmpasswordError, setConfirmpasswordError] = useState(false);
  const [confirmpasswordHelperText, setConfirmpasswordHelperText] = useState('');

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [nameHelperText, setNameHelperText] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (name.length < 1) {
      setNameHelperText("Please enter your email!");
      setNameError(true); // set error status to true
      return false;
    } else {
      setNameHelperText("");
      setNameError(false); // set error status to false
    }

    if (username.length < 1) {
      setUsernameHelperText("Please enter your email or username!");
      setUsernameError(true); // set error status to true
      return false;
    } else {
      setUsernameHelperText("");
      setUsernameError(false); // set error status to false
    }

    if (email.length < 1) {
      setEmailHelperText("Please enter your email!");
      setEmailError(true); // set error status to true
      return false;
    } else {
      setEmailHelperText("");
      setEmailError(false); // set error status to false
    }

    if (password.length < 1) {
      setPasswordHelperText("Please enter your password!");
      setPasswordError(true); // set error status to true
      return false;
    } else {
      setPasswordHelperText("");
      setPasswordError(false); // set error status to false
    }

    if (confirmpassword.length < 1) {
      setConfirmpasswordHelperText("Please confirm your password!");
      setConfirmpasswordError(true); // set error status to true
      return false;
    } else {
      setConfirmpasswordHelperText("");
      setConfirmpasswordError(false); // set error status to false
    }

    if (password !== confirmpassword) {
      setConfirmpasswordHelperText("Your confirm password doesn't match!");
      setConfirmpasswordError(true); // set error status to true
      return false;
    } else {
      setConfirmpasswordHelperText("");
      setConfirmpasswordError(false); // set error status to false
    }

    
    fetch(`https://www.tradehoonthai.com/api/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `username=${username}&password=${password}&email=${email}&name=${name}`
    })
    .then(response => response.json())
    .then(response => {
      if (response.status) {
        navigate('/welcome');
      } else {
        alert(response.message);
      }
    })
    .catch(err => { alert(err) });
  }


  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/original-logo.png)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Account
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              error={nameError}
              margin="normal"
              required
              fullWidth
              id="name"
              label="Your name"
              name="name"
              autoFocus
              onChange={e => {
                setName(e.target.value)
              }}
              helperText={nameHelperText}
            />
            <TextField
              error={usernameError}
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              onChange={e => {
                setUsername(e.target.value)
              }}
              helperText={usernameHelperText}
            />
            <TextField
              error={emailError}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              onChange={e => {
                setEmail(e.target.value)
              }}
              helperText={emailHelperText}
            />
            <TextField
              error={passwordError}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={e => { setPassword(e.target.value) }}
              helperText={passwordHelperText}
            />

            <TextField
              error={confirmpasswordError}
              margin="normal"
              required
              fullWidth
              name="confirmpassword"
              label="Confirm Password"
              type="password"
              id="confirmpassword"
              onChange={e => { setConfirmpassword(e.target.value) }}
              helperText={confirmpasswordHelperText}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleClick}
            >
              Sign Up
            </Button>
            <div align="center">OR</div>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              startIcon={<GoogleIcon />}
            >
              Sign up with Google
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  Sign in
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}