import * as React from 'react';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import ShowIfAuth from '../../modules/ShowIfAuth';

const LogoutButton = function() {

    const handleClick = function () {
        const token = Cookies.get('token');
        fetch('https://www.tradehoonthai.com/api/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(response => response.json())
            .then(response => {
                if (response.status) {
                    Cookies.remove('token');
                    alert(response.message);

                } else {
                    alert('Error');
                }
            });
    }
    return (<Button key="logoutbutton" sx={{ color: '#fff' }} href="" onClick={handleClick}>
        Logout
    </Button>);
}

export default ShowIfAuth(LogoutButton);