import * as React from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SecurityIcon from '@mui/icons-material/Security';

import ShowIfAuth from '../../../modules/ShowIfAuth';

const ChangepasswordButton = function () {
    return (
        <ListItemButton href="/change-password">
            <ListItemIcon>
                <SecurityIcon />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
        </ListItemButton>
    );
}

export default ShowIfAuth(ChangepasswordButton);