import Cookies from 'js-cookie';
export default async function Authenticate() {
    const token = Cookies.get('token');
    const promise = await new Promise(resolve => {
      fetch('https://www.tradehoonthai.com/api/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.status) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(function () {
          resolve(false);
        });
    });
    return await promise;
}