import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Cookies from 'js-cookie';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import DateTime from '../../elements/DateTime';
import { green, red } from '@mui/material/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Sidetxt from '../../elements/Sidetext';
import { TableFooter } from '@mui/material';
import PriceDisplay from '../../elements/PriceDisplay';
import TableContainer from '@mui/material/TableContainer';

const redColor = red[700];
const greenColor = green[700];

const getOrderdetail = function (fromdate, todate) {
  const token = Cookies.get('token');
  const from_date = fromdate.toISOString();
  const to_date = todate.toISOString();
  return fetch(`https://www.tradehoonthai.com/api/getOrders`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        from_date: from_date,
        to_date: to_date,
      })
    }
  ).then(response => response.json());
}



export default function MyTradingLogs() {
  /** Mytradinglog required  */
  const defaultTodate = new Date().toISOString().slice(0, 10); //it will be YYYY-mm-dd
  const defaultFromdate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10);//it will be YYYY-mm-dd

  const [fromdate, setFromdate] = useState(dayjs(defaultFromdate));
  const [todate, setTodate] = useState(dayjs(defaultTodate));

  const [rows, setRows] = useState([]);
  const [totalRealized, setTotalRealized] = useState(0);

  function createRow(trade_time, symbol, side, price, volume, matched, balance, avgPrice) {
    return { trade_time, symbol, side, price, volume, matched, balance, avgPrice };
  }

  const handlefromdate = function (newvalue) {
    setFromdate(newvalue);
  }
  const handletodate = function (newvalue) {
    setTodate(newvalue);
  }

  const handleViewOrder = function () {
    getOrderdetail(fromdate, todate).then(response => {
      if (response.status) {
        const orderRows = response.orders.map(item => {
          return createRow(item.trade_time, item.symbol, item.side, item.avgPrice.toFixed(2), item.vol, item.matched, item.balance, item.avgPrice.toFixed(2));
        });
        setRows(orderRows);
        setTotalRealized(response.totalRealized);
      } else {
        setRows([]);
      }
    });
  }

  useEffect(() => {
    getOrderdetail(fromdate, todate).then(response => {
      if (response.status) {
        const orderRows = response.orders.map(item => {
          return createRow(item.trade_time, item.symbol, item.side, item.avgPrice.toFixed(2), item.vol, item.matched, item.balance, item.avgPrice.toFixed(2));
        });
        setRows(orderRows);
        setTotalRealized(response.totalRealized);
      } else {
        setRows([]);
      }
    });
  }, []);
  /** Mytradinglog required  */

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Trading Logs
          </Typography>
          <Stack
            justifyContent="flex-start"
            alignItems="baseline"
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 3, sm: 3, md: 3 }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
              <DatePicker value={fromdate} onChange={handlefromdate}
                defaultValue={dayjs(defaultFromdate)}
                label="Please choose begin date"
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}
              adapterLocale="th">
              <DatePicker value={todate} onChange={handletodate}
                defaultValue={dayjs(defaultFromdate)}
                label="Please choose end date"
              />
            </LocalizationProvider>
            <Button variant="outlined" onClick={handleViewOrder} size="large">View logs</Button>
          </Stack>
          <Divider sx={{ marginTop: '30px' }} />
          <TableContainer sx={{ maxHeight: 440 }}>
          <Table sx={{ minWidth: 700 }} aria-label="spanning table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Symbol</TableCell>
                <TableCell align="left">Side</TableCell>
                <TableCell align="right">Avg Price</TableCell>
                <TableCell align="right">Volume</TableCell>
                <TableCell align="right">Matched</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.order_no}>
                  <TableCell align="left"><DateTime datetime={row.trade_time} /></TableCell>
                  <TableCell align="left">{row.symbol}</TableCell>
                  <TableCell align="center" sx={(row.side === 'S') ? { color: redColor } : { color: greenColor }}><Sidetxt side={row.side} /></TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                  <TableCell align="right">{(row.volume)}</TableCell>
                  <TableCell align="right">{(row.matched)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {/* <TableFooter>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"><Typography variant="subtitle2" display="inline">Total Realized P/L</Typography></TableCell>
                <TableCell align="right"><PriceDisplay floatValue={totalRealized} /></TableCell>
              </TableRow>
            </TableFooter> */}
          </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}