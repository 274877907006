import * as React from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';

import Cookies from 'js-cookie';
import ShowIfAuth from '../../../modules/ShowIfAuth';

const LogoutButton = function () {

    const handleClick = function () {
        const token = Cookies.get('token');
        fetch('https://www.tradehoonthai.com/api/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(response => response.json())
            .then(response => {
                if (response.status) {
                    Cookies.remove('token');
                    alert(response.message);
                    window.location.reload();
                } else {
                    alert('Error');
                }
            });
    }
    return (
        <ListItemButton>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" onClick={handleClick} />
        </ListItemButton>
    );
}

export default ShowIfAuth(LogoutButton);