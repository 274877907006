import React from 'react';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
const redColor = red[700];
const greenColor = green[700];
export default function PriceDisplay(props) {
    const { floatValue } = props;
    let response;
    let textColor;

    if (floatValue > 0) {
        response = `+${parseFloat(floatValue).toFixed(2)}`;
        textColor = greenColor;
    } else {
        response = `${parseFloat(floatValue).toFixed(2)}`;
        textColor = redColor;
    }
    return (
        <Typography variant="subtitle1" display="inline" sx={{ color: textColor }}>{response}</Typography>
    );
}