import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="/">
          tradehoonthai.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}{' | '}
        <Link color="inherit" href="/privacy-policy">
          Privacy policy
        </Link>{' | '}
        <Link color="inherit" href="/cookie-policy">
          Cookie policy
        </Link>
      </Typography>
    );
  }