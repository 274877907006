import * as React from 'react';

import HideIfAuth from '../../modules/HideIfAuth';


import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const NotAuthHambergerMenu = function () {
    return (
        <span>
          <ListItem key="hambergerLogin" disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href="/login">
              <ListItemText primary="Sign in" />
            </ListItemButton>
          </ListItem>
          <ListItem key="hambergerSignup" disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href="/signup">
              <ListItemText primary="Sign up" />
            </ListItemButton>
          </ListItem>
        </span>
    );
}

export default HideIfAuth(NotAuthHambergerMenu);