import React from 'react';
import DrawerAppBar from '../templates/AppBar';
import PrivacyPolicyContent from '../static_contents/PrivacyPolicyContent';
import Footer from '../templates/Footer';
import StaticContentBox from '../templates/StaticContentBox';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div>
                <DrawerAppBar />
                <StaticContentBox content={<PrivacyPolicyContent />} />
                <Footer />
            </div>
        )
    }
}

export default PrivacyPolicy;