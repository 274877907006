import React from 'react';
import DrawerAppBar from '../templates/AppBar';
import Footer from '../templates/Footer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Divider } from "@mui/material";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import HomeTabs from '../templates/HomeTabs';
import Paper from '@mui/material/Paper';
export default function Welcome() {
    const imageStyle = {
        width: '150px',
        height: 'auto',
    };
    return (
        <div>
            <DrawerAppBar />

            <Container component="main" maxWidth="lg" sx={{ marginTop: '100px' }}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <img src="images/original-logo.png" style={imageStyle} />
                        </Grid>
                        <Grid item md={7}>
                            <h2>Trade Hoon Thai 1.0.0</h2>
                            <p>Developed by tradehoonthai.com</p>
                        </Grid>
                        <Grid item md={3}>
                            <Button variant="contained">Download</Button>
                        </Grid>
                    </Grid>
                    <Divider />
                    <HomeTabs />
                </Box>
            </Container>
            <Footer />
        </div>
    )
}