import * as React from 'react';
import Button from '@mui/material/Button';
import HideIfAuth from '../../modules/HideIfAuth';

const NotAuthMenu = function () {
    return (
        <span>
            <Button key="publicLoginTopMenu" sx={{ color: '#fff' }} href="/login">
                Sign in
            </Button>
            <Button key="publicLoginTopMenu" sx={{ color: '#fff' }} href="/signup">
                Sign up
            </Button>
        </span>
    );
}

export default HideIfAuth(NotAuthMenu);