import React, { useEffect, useState } from 'react';
import Authenticate from './Authenticate';

const ShowIfAuth = (Component) => {
  const AuthenticatedComponent = (props) => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
      async function checkAuthentication() {
        const isAuthenticated = await Authenticate();
        setAuthenticated(isAuthenticated);
      }

      checkAuthentication();
    }, []);

    if (!authenticated) {
      return null;
    }

    return <Component {...props} />;
  };

  return AuthenticatedComponent;
};

export default ShowIfAuth;
