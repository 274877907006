import React from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

class StaticContentBox extends React.Component {
    render() {
        return (
            <Container component="main" maxWidth="lg">
                <Paper variant="outlined" sx={{ my: { xs: 8, md: 8 }, p: { xs: 8, md: 8 } }}>
                    {this.props.content}
                </Paper>
            </Container>
        )
    }
}

export default StaticContentBox;