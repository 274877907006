import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignInSide from "./components/pages/SignInSide";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import CookiePolicy from "./components/pages/CookiePolicy";
import Signup from "./components/pages/Signup";
import Welcome from "./components/dashboard/contents/WelcomeContent";
import Home from "./components/pages/Home";
import { cyan } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import VerifyAuth from "./modules/VerifyAuth";
import Dashboard from "./components/dashboard/Dashboard";
import MyTradingLogs from './components/dashboard/contents/MyTradingLogs';
import ChangePassword from './components/dashboard/contents/ChangePassword';
import MyPortfolio from './components/dashboard/contents/MyPortfolio';

const theme = createTheme({
  palette: {
    primary: {
      main: cyan['A700'],
    },
    secondary: {
      main: cyan['A400'],
    },
  },
});


export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: <SignInSide />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/cookie-policy",
      element: <CookiePolicy />,
    },
    {
      path: "/welcome",
      element: <Dashboard contentComponent={Welcome}></Dashboard>,
    },
    {
      path: "/change-password",
      element: <Dashboard contentComponent={ChangePassword}></Dashboard>,
      loader: VerifyAuth,
    },
    {
      path: '/mytradinglogs',
      element: <Dashboard contentComponent={MyTradingLogs}></Dashboard>,
      loader: VerifyAuth,
    },
    {
      path: '/portfolio',
      element: <Dashboard contentComponent={MyPortfolio}></Dashboard>,
      loader: VerifyAuth,
    }
  ]);
  return <ThemeProvider theme={theme}><RouterProvider router={router} /></ThemeProvider>
};