import * as React from 'react';
import Button from '@mui/material/Button';
import ShowIfAuth from '../../modules/ShowIfAuth';

const AuthMenu = function() {


    return (<Button key="dashboard" sx={{ color: '#fff' }} href="/welcome">
        Dashboard
    </Button>);
}

export default ShowIfAuth(AuthMenu);