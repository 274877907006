import * as React from "react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Cookies from 'js-cookie';

export default function ChangePassword() {
  const [password, setPassword] = useState(null);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState(null);

  const [newpassword, setNewpassword] = useState(null);
  const [newpasswordHasError, setNewpasswordHasError] = useState(false);
  const [newpasswordHelperText, setNewpasswordHelperText] = useState(null);

  const [confirmedpassword, setConfirmedpassword] = useState(null);
  const [confirmedpasswordHasError, setConfirmedpasswordHasError] = useState(false);
  const [confirmedpasswordHelperText, setConfirmedpasswordHelperText] = useState(null);

  const handleOnSubmit = (e) => {
    if (newpassword < 6) {
      setNewpasswordHasError(true);
      setNewpasswordHelperText('Please note that the password length must be greater than 6 characters.');
      return;
    }

    if (confirmedpassword < 6) {
      setConfirmedpasswordHasError(true);
      setConfirmedpasswordHelperText('Please note that the password length must be greater than 6 characters.');
      return;
    }

    if (confirmedpassword !== newpassword) {
      setConfirmedpasswordHasError(true);
      setConfirmedpasswordHelperText('The confimed password does not match.');
      return;
    }

    if (password === newpassword) {
      setNewpasswordHasError(true);
      setNewpasswordHelperText('Please ensure that the new password is different from the old password.');
      return;
    }
    
    const token = Cookies.get('token');
    const checkPassword = fetch(`https://www.tradehoonthai.com/api/changePassword`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: password,
          newpassword: confirmedpassword,
        })
      }
    ).then(response => response.json());

    checkPassword.then(data => {
      if (data.status == true) {
        window.location.reload();
      } else {
        setPasswordHasError(true);
        setPasswordHelperText('The old password provided is not correct.');
        return;
      }
    });
  }

  const handleOnchangeOldpassword = (e) => {
    setPassword(e.target.value);
    setPasswordHasError(false);
    setPasswordHelperText(null);
  }

  const handleOnchangeNewpassword = (e) => {
    setNewpassword(e.target.value);
    setNewpasswordHasError(false);
    setNewpasswordHelperText(null);
  }

  const handleOnchangeConfirmedpassword = (e) => {
    setConfirmedpassword(e.target.value);
    setConfirmedpasswordHasError(false);
    setConfirmedpasswordHelperText(null);
  }
  return (
    <main>
      {/* Hero unit */}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Change password
          </Typography>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: '0 0 10px 0', width: '100%' },
            }}
            noValidate
            autoComplete="off"
            display="flex" flexDirection="column"
          >
            <TextField error={passwordHasError} helperText={passwordHelperText} label="Old password" id="outlined-size-normal" defaultValue="" placeholder="Enter your old password" type="password" onChange={handleOnchangeOldpassword} />

            <TextField error={newpasswordHasError} label="New password" id="outlined-size-normal" defaultValue="" placeholder="Enter your New password" type="password" onChange={handleOnchangeNewpassword} helperText={newpasswordHelperText} />

            <TextField label="Confirm new password" id="outlined-size-normal" defaultValue="" placeholder="Confirm new password" type="password" onChange={handleOnchangeConfirmedpassword} error={confirmedpasswordHasError} helperText={confirmedpasswordHelperText} />
            <Button variant="contained" size="large" onClick={handleOnSubmit}>Update</Button>
          </Box>
        </Container>
      </Box>
    </main>
  );

}