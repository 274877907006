import React from 'react';
import DrawerAppBar from '../templates/AppBar';
import Footer from '../templates/Footer';
import StaticContentBox from '../templates/StaticContentBox';
import CookiePolicyContent from '../static_contents/CookiePolicyContent';

class CookiePolicy extends React.Component {
    render() {
        return (
            <div>
                <DrawerAppBar />
                <StaticContentBox content={<CookiePolicyContent />} />
                <Footer />
            </div>
        )
    }
}

export default CookiePolicy;