import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Divider } from '@mui/material'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Item(props) {
    return (
        <Box>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>
        </Box>
    )
}
export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const imgStyled = {
        width: '100%',
        height: 'auto',
    }
    var items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!",
            src: "images/screenshot/screen1.png",
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            src: "images/screenshot/screen2.png",
        },
        {
            name: "Random Name #3",
            description: "Hello World!",
            src: "images/screenshot/screen3.png",
        },
        {
            name: "Random Name #4",
            description: "Hello World!",
            src: "images/screenshot/screen4.png",
        }
    ]


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Support" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Carousel

                >
                    {
                        items.map((item, i) => <img src={item.src} key={i} style={imgStyled} />)
                    }
                </Carousel>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        <Paper sx={{ padding: '20px' }}>
                            <h2>Overview</h2>
                            <p>Extension สำหรับใช้เทรดหุ้นไทย เหมาะสำหรับ Trader สาย Scalping, Daytrade, Swing trade</p>

                            <h2>แค่คลิกเดียวก็รู้ข้อมูลสำคัญ ๆ ของหุ้นได้ทันที</h2>
                            <ul>
                                <li>ระบบแจ้งเตือนหุ้นที่ <strong>เสี่ยงติด Cash วัน และ Cash สัปดาห์</strong></li>
                                <li>มีระบบคัดกรองหุ้นซิ่ง โดยจะแสดงสัญลักษณ์ดาว 1-5 ดาว หากเห็นหุ้น 4-5 ดาวสามารถรับรู้ได้ทันทีว่าหุ้นตัวนี้มีโอกาสวิ่งได้แรง หุ้นเบาเจ้าลากได้ง่าย ไม่เสี่ยงติด Cash ง่าย ๆ เพราะจะเป็นหุ้นมี PE ไม่สูงเงื่อนไข Cash</li>
                                <li>ดูมูลค่าซื้อขายเฉลี่ย 7 วันล่าสุดได้ (Value Trade เฉลี่ย 7 วัน)</li>
                                <li>ดูกลุ่มหุ้น <strong>Correlation</strong> ได้</li>
                                <li>ดูพฤติกรรมราคาเปิดของหุ้นได้ เช่น หุ้นชอบเปิดโดดแรง หรือ เปิดกดแรง โดยใช้ข้อมูลในอดีตมาวิเคราะห์</li>
                                <li>ตรวจสอบข้อมูลสำคัญ ที่ Trader นิยมดู เช่น Freefloat, Market Cap, ประเภทธุรกิจ, Pe, ข้อมูลงบการเงิน, ข่าวล่าสุดที่ประกาศบนเว็บ SET ฯลฯ</li>
                                <li>แสดงค่า PE หุ้นแบบ Real Time</li>
                                <li>มีระบบ <strong>"My Note"</strong> สามารถเขียนแผนการเทรด หรือนิสัยหุ้นใส่ Note ไว้ได้</li>
                            </ul>
                            <Divider />

                            <h2>ดูหุ้นทันมือเก๋า เลิกตาลายเวลาเฝ้าหน้า Ticker</h2>
                            <ul>
                                <li>ระบบตรวจจับและแจ้งเตือนด้วยเสียงเมื่อมีไม้ใหญ่เคาะซื้อ เช่น ไม้ล้านเคาะยกช่องจุด Breakout</li>
                                <li>มีระบบคัดกรองหุ้นซิ่ง โดยจะแสดงสัญลักษณ์ดาว 1-5 ดาว หากเห็นหุ้น 4-5 ดาวสามารถรับรู้ได้ทันทีว่าหุ้นตัวนี้มีโอกาสวิ่งได้แรง หุ้นเบาเจ้าลากได้ง่าย ไม่เสี่ยงติด Cash ง่าย ๆ เพราะจะเป็นหุ้นมี PE ไม่สูงเงื่อนไข Cash</li>
                                <li>เมื่อท่านกำลังเฝ้าหุ้นที่หน้า Ticker เห็นหุ้นกำลังวิ่งอยู่ สามารถกดดูข้อมูลหุ้นตัวนั้นได้ทันทีจากหน้า Ticker</li>
                                <li>ดูกราฟหุ้น  Time frame Day และ Time Frame 1 นาทีจากหน้า Ticker ได้ทันที</li>
                                <li>เห็น %Chg, Freefloat และ Market Cap ของหุ้นที่กำลังวิ่งในหน้า Ticker <strong>(ปกติหน้า Ticker จะไม่แสดงข้อมูลนี้)</strong></li>
                                <li>ตั้งค่าให้หน้า Ticker แสดงเฉพาะหุ้นที่เขียว ตัดหุ้นแดงออก</li>
                                <li>ตั้งค่าให้หน้า Ticker แสดงเฉพาะหุ้น Freefloat ต่ำ ๆ</li>
                                <li>ตั้งค่าให้หน้า Ticker แสดงเฉพาะหุ้นที่มีขนาด Market Cap ตามที่เราต้องการ</li>
                                <li>ตั้งค่าให้หน้า Ticker แสดงเฉพาะหุ้นที่มีไม้ใหญ่เคาะซื้อ ไม้บอทไม้เล็กไม้น้อยตัดออกได้</li>
                            </ul>
                            <Divider />
                            <h2>เทรดหลายจอ ดูกราฟ 3 จอ 3 Time Frame ไปเลย</h2>
                            <ul>
                                <li>ระบบแสดงกราฟหุ้น 3 จอ หรือ 3 Time Frame</li>
                                <li>ตั้งค่า Time Frame และ Indicator ของแต่ละจอแยกกันได้</li>
                                <li>กราฟแต่ละจอจะเปลี่ยนให้อัตโนมัติ ไม่ต้องเสียเวลาพิมพ์เปลี่ยนเองทีละจอ</li>
                            </ul>
                            <Divider />
                            <h2>ระบบแจ้งเตือนหุ้นจ่อ Break หุ้นจ่อหลุด Low</h2>
                            <ul>
                                <li>ตั้งค่าแจ้งเตือนหุ้นด้วยเสียงเมื่อราคาขยับขึ้นถึงราคาที่เรากำหนดไว้</li>
                                <li>ถ้าเป็นสายรอช้อนสามารถตั้งให้แจ้งเตือนเมื่อหุ้นร่วงลงมาถึงราคาที่เราต้องการได้</li>
                            </ul>
                            
                            <Divider />
                            <h2>ระบบคำนวณ Position สำหรับกำหนดไม้เทรด</h2>
                            <ul>
                                <li>กำหนดผลกำไรขาดทุนต่อช่องได้ง่ายขึ้น คำนวณหาจำนวนหุ้นที่ควรซื้อ</li>
                            </ul>

                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{padding: '20px'}}>
                            <h2>Additional Information</h2>
                            <p><strong>Version</strong><br/>1.0.0</p>

                            <p><strong>Updated</strong><br/>March 12, 2023</p>

                            <p><strong>Languages</strong><br/>English, Thai</p>

                            <p><strong>Developer</strong><br/>tradehoonthai.com</p>
                        </Paper>
                    </Grid>
                </Grid>

            </TabPanel>

            <TabPanel value={value} index={1}>
            ติดต่อสอบถามหรือแจ้งปัญหาการใช้งานได้ที่ <a href="mailto:tradehoonthai@gmail.com">Email</a>
            </TabPanel>
        </Box>
    );
}